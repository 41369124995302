import { Alert, Box, MenuItem, Select } from "@mui/material";
import {
  CommonErrorBoundary,
  OAuthManager,
} from "@plansoft-configuration-renderer/components";
import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { MinimalAppContext } from "./MinimalAppContext";
import { MinimalRenderer } from "./MinimalRenderer";

// const cfgObjectId = window.location.hash.split("/")[1];
// export const language = window.location.hash.split("/")[2];
// const cfgSessionId = window.location.hash.split("/")[3];

const cfgObjects = [
  "CFG_FRONTEND_DEMO",
  "CFG_FRONTEND_DEMO_II",
  "CFG_KONFIG_STANDARD",
  "CFG_PROFI_KONFIGURATOR",
  "CFG_GERAETETRAEGER",
];

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const cfgObjectId = urlParams.get("cfgObjectId");
const cfgSessionId = urlParams.get("cfgSessionId");
export const language = urlParams.get("language");

console.log({ cfgObjectId });
console.log({ cfgSessionId });

export function MinimalApp(): JSX.Element {
  return (
    <MinimalAppContext>
      <GatewayProvider>
        <CommonErrorBoundary>
          {cfgObjectId || cfgSessionId ? (
            <MinimalRenderer
              baseUrl={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
              cfgObjectId={cfgObjectId ? cfgObjectId : ""}
              sessionId={cfgSessionId ? cfgSessionId : ""}
              language={language ? language : "de-DE"}
            />
          ) : (
            <Alert severity="error">
              <Box marginBottom={"0.75rem"}>
                No cfgObjectId set. Please set an cfgObject or select the
                cfgObject you were looking for. When including this application
                in an iFrame, check the url of the iFrame for the correct
                cfgObjectId.
              </Box>
              <Select
                defaultValue=""
                sx={{ minWidth: "200px" }}
                onChange={(event) => {
                  window.location.href = "/?cfgObjectId=" + event.target.value;
                }}
              >
                {cfgObjects.map((cfgObject) => {
                  return (
                    <MenuItem value={cfgObject} key={cfgObject}>
                      {cfgObject}
                    </MenuItem>
                  );
                })}
              </Select>
            </Alert>
          )}
        </CommonErrorBoundary>
        <OAuthManager
          basePath={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
          isLocalDevelopment={import.meta.env.DEV}
        />

        <Box position="sticky" top={0}>
          <GatewayDest name="navigation" />
        </Box>
      </GatewayProvider>
    </MinimalAppContext>
  );
}

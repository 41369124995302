import { ReactNode } from "react";

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";

import { BasicTableBody } from "./BasicTableBody";
import { BasicTableHeader } from "./BasicTableHeader";
import type { BasicTable } from "./types";

export function BasicTable<
  Row extends { id: number } & { [K in keyof Row]: ReactNode },
>({ headers, rows }: BasicTable<Row>): JSX.Element {
  return (
    <TableContainer>
      <Table aria-label="basicTable">
        <BasicTableHeader headers={headers} />
        <BasicTableBody headers={headers} rows={rows} />
      </Table>
    </TableContainer>
  );
}

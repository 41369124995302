import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box/Box";

export function LoadingSpinner(): JSX.Element {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
}

export function FloatingLoadingSpinner(props: BoxProps): JSX.Element {
  return (
    <Box
      {...props}
      width="100%"
      height="100%"
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={1000}
      sx={{
        pointerEvents: "all",
        backgroundColor: "rgb(255 255 255 / 32%)",
        animation: "50% 0.2s",
      }}
    >
      <LoadingSpinner />
    </Box>
  );
}

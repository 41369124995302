import { Fragment, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CustomLayoutProps } from "@plansoft-configuration-renderer/layout";

import { RegioluxArrowIcon } from "@/icons/RegioluxIcons";
import { customPalette } from "@/theme/theme";

/**
 * Props for {@link IndividualContainerMapper}
 */
export type IndividualContainerMapperProps = {
  /**
   * Variant to distinguish between different individual components
   */
  variant:
    | "complex_ui_configurator_sidebar"
    | "complex_ui_configurator_container"
    | string;
  /**
   * Child components, inserted by layout
   */
  children: ReactNode;
} & Required<CustomLayoutProps>;

export function IndividualContainerMapper(
  props: IndividualContainerMapperProps,
): JSX.Element {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  if (props.variant === "complex_ui_configurator_container") {
    return (
      <Fragment>
        <Box className="complex_ui_configurator_container">
          {props.children}
        </Box>
      </Fragment>
    );
  } else if (props.variant === "complex_ui_configurator_sidebar") {
    return (
      <Fragment>
        {open ? (
          <Grid className="complex_ui_configurator_sidebar">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              borderBottom={(theme) =>
                `1px solid ${theme.palette.secondary.light}`
              }
            >
              <Typography variant={"h5"} textTransform={"uppercase"}>
                {t("sidebar_Header")}
              </Typography>
              <Button
                variant="text"
                fullWidth={false}
                sx={{ padding: 0, minWidth: "inherit" }}
                onClick={() => setOpen(false)}
              >
                <RegioluxArrowIcon direction="left" type="short" />
              </Button>
            </Box>

            {props.children}
          </Grid>
        ) : (
          <Grid
            className="complex_ui_configurator_sidebar_closed"
            onClick={() => setOpen(true)}
            sx={{
              borderBottom: `1px solid ${customPalette.secondary.light}`,
            }}
          >
            <RegioluxArrowIcon direction="right" type="short" />
          </Grid>
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Alert severity="warning">
        {t(
          "Individual containers not supported",
          "Individual containers are not supported by the default renderer and display only their content",
        )}
      </Alert>
      {props.children}
    </Fragment>
  );
}

import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { useQuery } from "@tanstack/react-query";

import { LoadingSpinner } from "@/tools/LoadingSpinner";
import { fetchUserDefinedGetter } from "@/tools/api";
import { tryParseOutputData } from "@/tools/helper";

import { ProductInfo } from "./ProductInfo";

export type ProductInfo = {
  image_src?: string;
  subheader?: string;
  description?: string;
};

type ProductInfoResponse = {
  id: string;
  name: string;
  releaseId: string;
  output: ProductInfo;
  localeId: string;
  currencyId: string;
  currencySymbol: string;
  isReadOnly: boolean;
  context: object;
  properties: object;
  objects: object[];
};

export function ProductInfoData(): JSX.Element {
  const sessionId = useSessionId();

  const {
    data: productInfoData,
    isFetching,
    isSuccess,
  } = useQuery<ProductInfoResponse>({
    queryKey: ["productInfo"],
    queryFn: () =>
      fetchUserDefinedGetter(sessionId, "getProductInfo", tryParseOutputData),
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {isSuccess && (
        <ProductInfo
          image_src={productInfoData.output.image_src}
          subheader={productInfoData.output.subheader}
          description={productInfoData.output.description}
        />
      )}
    </>
  );
}

import { Fragment, useContext, useMemo } from "react";

import {
  CsAttributeController,
  ShowInactiveAttributesContext,
  ShowNotVisibleAttributesContext,
  useCsAttributes,
} from "@plansoft-configuration-renderer/framework";

export function ProvideAttributeContextByPath({
  path,
  children,
}: {
  path: string;
  children?: React.ReactNode;
}): JSX.Element {
  const attributes = useCsAttributes();
  const showInactiveAttributes = useContext(ShowInactiveAttributesContext);
  const showNotVisibleAttributes = useContext(ShowNotVisibleAttributesContext);

  const activeAttributes = useMemo(() => {
    return attributes
      .filter((attribute) => attribute.isActive || showInactiveAttributes)
      .filter((attribute) => attribute.isVisible || showNotVisibleAttributes);
  }, [attributes, showInactiveAttributes, showNotVisibleAttributes]);

  const target = activeAttributes.find((attribute) => attribute.id === path);

  if (!target) {
    return <Fragment />;
  }

  return (
    <CsAttributeController key={target.id} attribute={target}>
      {children}
    </CsAttributeController>
  );
}

import { Fragment, ReactNode, SyntheticEvent } from "react";

import { Tooltip } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import {
  ConfigurationStatusButton,
  useIconPalette,
} from "@plansoft-configuration-renderer/components";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";
import { CsCfgObjectStubDebug } from "@plansoft-configuration-renderer/debug";
import {
  useCsAttribute,
  useSubstructureApi,
  useSubstructureMeta,
} from "@plansoft-configuration-renderer/framework";
import { useUiTranslation } from "@plansoft-configuration-renderer/localization";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";

interface IconButtonWithTooltipProps {
  title: string;
  disabled?: boolean;
  hideDisabled: boolean;
  icon: ReactNode;
  onClick: () => void;
}

function IconButtonWithTooltip(props: IconButtonWithTooltipProps): JSX.Element {
  if (props.hideDisabled && props.disabled) {
    return <Fragment />;
  }

  return (
    <Tooltip title={props.title}>
      {/* Without the additional span, the tooltip won't work for disabled buttons */}
      <span>
        <IconButton
          disabled={props.disabled}
          type="button"
          onClick={props.onClick}
        >
          {props.icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}

function stopPropagation(e: SyntheticEvent): void {
  // if this bubbles up, it can trigger accordion / list actions
  e.stopPropagation();
}

export function CustomSubstructureInstanceButtons({
  substructure,
}: {
  substructure: ConfigurationObjectResult;
}): JSX.Element {
  const { t } = useUiTranslation();
  const { selectedSubstructure, setSelectedSubstructure } =
    useSubstructureStandaloneContentCtx();

  const attribute = useCsAttribute();

  const { copySubCfgObject, deleteSubCfgObject, resetSubCfgObject } =
    useSubstructureApi();

  const { canDelete, canCopy } = useSubstructureMeta();
  const Icons = useIconPalette();

  const instancePath = `${attribute.path}/${substructure.index}`;

  const hideDisabled = attribute.cardinalityMax === 1;

  return (
    <ButtonGroup
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      sx={{ marginLeft: "-0.5rem" }}
    >
      <IconButtonWithTooltip
        disabled={false}
        hideDisabled={hideDisabled}
        title={t("Edit substructure", "Edit")}
        onClick={() => setSelectedSubstructure(substructure)}
        icon={<Icons.SubstructureEditIcon />}
      />
      <IconButtonWithTooltip
        title={t("Copy substructure", "Copy")}
        disabled={!canCopy}
        hideDisabled={hideDisabled}
        onClick={() => copySubCfgObject({ path: instancePath })}
        icon={<Icons.SubstructureCopyIcon />}
      />
      <IconButtonWithTooltip
        title={t("Delete substructure", "Delete")}
        disabled={!canDelete}
        hideDisabled={hideDisabled}
        onClick={() => {
          deleteSubCfgObject({ path: instancePath });
          if (selectedSubstructure?.guid === substructure.guid) {
            setSelectedSubstructure(null);
          }
        }}
        icon={<Icons.SubstructureDeleteIcon />}
      />

      <IconButtonWithTooltip
        disabled={false}
        hideDisabled={hideDisabled}
        title={t("Reset substructure", "Reset")}
        onClick={() => resetSubCfgObject({ path: instancePath })}
        icon={<Icons.ConfigurationResetIcon />}
      />
      <ConfigurationStatusButton cfgObject={substructure} />
      <CsCfgObjectStubDebug substructure={substructure} />
    </ButtonGroup>
  );
}

CustomSubstructureInstanceButtons.displayName =
  "CustomSubstructureInstanceButtons";

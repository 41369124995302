import ReactMarkdown from "react-markdown";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { customPalette } from "@/theme/theme";

import type { ProductInfo } from "./ProductInfoData";

export function ProductInfo({
  image_src,
  subheader,
  description,
}: ProductInfo): JSX.Element {
  return (
    <Box>
      <img
        src={image_src}
        style={{
          width: "100%",
          marginBottom: "0.5rem",
          backgroundColor: customPalette.secondary.light,
        }}
      />
      <Box
        height={"auto"}
        paddingLeft={"0.5rem"}
        display={"flex"}
        flexDirection={"column"}
        gap={"0.5rem"}
      >
        <Typography variant={"body1"} fontWeight={"500"}>
          {subheader}
        </Typography>
        <Typography
          variant={"body2"}
          whiteSpace={"pre-wrap"}
          lineHeight={1.4}
          fontFamily={"Roboto, Oswald, sans-serif"}
        >
          <ReactMarkdown>{description}</ReactMarkdown>
        </Typography>
      </Box>
    </Box>
  );
}

import {
  AttributeComponents,
  AttributeStrategy,
  ConfigurationComponentType,
  ConfigurationComponents,
  ControlComponents,
  ControlComponentsMapping,
  LayoutComponentProvider,
  LayoutControlType,
} from "@plansoft-configuration-renderer/components";

import { SubstructureStandaloneContentCtxProvider } from "@/renderer/SubstructureStandaloneContentCtx";
import { IndividualComponentMapper } from "@/renderer/controls/IndividualComponentMapper/IndividualComponentMapper";
import { SubstructureMapper } from "@/renderer/controls/SubstructureMapper/SubstructureMapper";

import { NavigationOutsideLayout } from "./NavigationOutsideLayout";
import { BomContextProvider } from "./controls/BillOfMaterials/BomContextProvider";
import { CustomCheckbox } from "./controls/CustomCheckbox";
import { CustomInfoValuesMapper } from "./controls/CustomInfoValues/CustomInfoValuesMapper";
import { CustomSubconfigurationNavigation } from "./controls/CustomSubconfigurationNavigation/CustomSubconfigurationNavigation";
import { CustomThumbnail } from "./controls/CustomThumbnail";
import { IndividualContainerMapper } from "./controls/IndividualContainerMapper/IndividualContainerMapper";

// override control components for attributes
const controlComponents: ControlComponentsMapping = {
  ...ControlComponents, // the default components
  [LayoutControlType.Checkboxes]: CustomCheckbox,
  [LayoutControlType.Thumbnail]: CustomThumbnail,
  [LayoutControlType.InfoValues]: CustomInfoValuesMapper,
  [LayoutControlType.Substructure]: SubstructureMapper,
};

// override configuration level components
const configurationComponents = {
  ...ConfigurationComponents,
  [ConfigurationComponentType.ConfigurationNavigation]: NavigationOutsideLayout,
  [ConfigurationComponentType.SubconfigurationNavigation]:
    CustomSubconfigurationNavigation,
  [ConfigurationComponentType.Individual]: IndividualComponentMapper,
  [ConfigurationComponentType.IndividualContainer]: IndividualContainerMapper,
};

// override attribute components
const attributeComponents = {
  ...AttributeComponents,
};

export function CustomRenderer(): JSX.Element {
  // see properties of LayoutComponentProvider for more information
  return (
    <SubstructureStandaloneContentCtxProvider>
      <BomContextProvider>
        <LayoutComponentProvider
          configurationComponents={configurationComponents}
          attributeComponents={attributeComponents}
          controlComponents={controlComponents}
        >
          {/* Attribute strategy renders the components using the layout information */}
          <AttributeStrategy />
        </LayoutComponentProvider>
      </BomContextProvider>
    </SubstructureStandaloneContentCtxProvider>
  );
}

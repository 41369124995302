import { HTMLAttributes } from "react";

type ArrowProps = {
  type: "short" | "long";
  direction: Direction;
};

type Direction = "down" | "left" | "up" | "right";

export function RegioluxAddIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE911"}
    </span>
  );
}

export function RegioluxArrowIcon({
  type,
  direction,
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement> & ArrowProps): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        rotate: getDegree(direction),
        ...style,
      }}
    >
      {type === "short" ? "\uE90F" : "\uE90C"}
    </span>
  );
}

export function RegioluxCheckmarkIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE926"}
    </span>
  );
}

export function RegioluxCloseIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE917"}
    </span>
  );
}

export function RegioluxDeleteIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE925"}
    </span>
  );
}

export function RegioluxDownloadIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE901"}
    </span>
  );
}

export function RegioluxFilterIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE903"}
    </span>
  );
}

export function RegioluxHamburgerMenuIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE900"}
    </span>
  );
}

export function RegioluxHomeIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE904"}
    </span>
  );
}

export function RegioluxMessageIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE902"}
    </span>
  );
}

export function RegioluxPrinterIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE93D"}
    </span>
  );
}

export function RegioluxRefreshIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE922"}
    </span>
  );
}

export function RegioluxSlidersIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "regiolux-iconset",
        ...style,
      }}
    >
      {"\uE906"}
    </span>
  );
}

export function RegioluxResetIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        ...style,
      }}
    >
      <img src="/Icons/resetSelection.svg" />
    </span>
  );
}

function getDegree(direction: Direction): string {
  switch (direction) {
    case "down":
      return "90deg";
    case "left":
      return "180deg";
    case "up":
      return "270deg";
    case "right":
    default:
      return "0deg";
  }
}

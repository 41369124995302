import Box from "@mui/material/Box";
import {
  SubstructureLabel,
  SubstructureScrollRestoreBehavior,
} from "@plansoft-configuration-renderer/components";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";

import { customPalette } from "@/theme/theme";

type CustomProductCardProps = {
  substructure: ConfigurationObjectResult;
};

export function CustomProductCard({
  substructure,
}: CustomProductCardProps): JSX.Element {
  return (
    <Box display={"flex"} gap={"0.5rem"}>
      <img
        // src={"/Example_img.png"}
        src={substructure.image ? substructure.image : ""}
        style={{
          width: "100%",
          backgroundColor: customPalette.primary.light,
        }}
      />
      <SubstructureLabel substructure={substructure} />
      <SubstructureScrollRestoreBehavior instancePath={substructure.path} />
    </Box>
  );
}

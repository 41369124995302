import { Fragment, ReactNode, useContext, useState } from "react";

import IconButton from "@mui/material/IconButton";
import {
  CommonComponentsContext,
  useIcon,
} from "@plansoft-configuration-renderer/components";

import { customPalette } from "@/theme/theme";

interface HelpPopoverProps {
  children: ReactNode;
  size?: "small" | "medium" | "large";
}

export function CustomHelpPopover({
  children,
  size = "small",
}: HelpPopoverProps): JSX.Element {
  const InfoIcon = useIcon("InfoIcon");
  const [isOpen, setIsOpen] = useState(false);

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const Components = useContext(CommonComponentsContext);

  return (
    <Fragment>
      <IconButton
        size={size}
        onMouseDown={(e): void => {
          e.stopPropagation();
        }}
        onMouseUp={(e): void => {
          e.stopPropagation();
        }}
        onClick={(e): void => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorElement(e.currentTarget);
          setIsOpen(true);
        }}
      >
        <Fragment>
          <InfoIcon
            sx={{
              color: "white",
              backgroundColor: customPalette.secondary.main,
              borderRadius: "50%",
            }}
          />
        </Fragment>
      </IconButton>
      <Components.StandardPopover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        anchorElement={anchorElement}
      >
        {children}
      </Components.StandardPopover>
    </Fragment>
  );
}

import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { RegioluxArrowIcon } from "@/icons/RegioluxIcons";

export function CustomAddToShoppingCartButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ marginTop: "1rem" }}
      endIcon={
        <RegioluxArrowIcon
          type="short"
          direction="right"
          style={{ fontSize: "0.8rem" }}
        />
      }
      onClick={() => {
        console.log(
          "add to shopping cart",
        ); /* TODO {sh}: implement endpoint call ("addToCart") */
      }}
    >
      {t("add_to_shopping_cart")}
    </Button>
  );
}

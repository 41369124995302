import { ReactNode, useState } from "react";

import { BomOpenContext } from "./BomContext";

export function BomContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [isBomOpen, setIsBomOpen] = useState(false);
  const value = { isBomOpen, setIsBomOpen };

  return (
    <BomOpenContext.Provider value={value}>{children}</BomOpenContext.Provider>
  );
}

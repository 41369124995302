import { ReactNode } from "react";

import Grid from "@mui/material/Grid";
import {
  CsValueController,
  useCsAttribute,
} from "@plansoft-configuration-renderer/framework";

export interface InfoValuesProps {
  children?: ReactNode;
}

interface CustomLayoutProps {
  custom: {
    style?: string;
  };
}

/**
 * Displays selected values as help text or markdown
 * @param props
 * @returns
 */
export function CustomInfoValues(
  props: InfoValuesProps & Partial<CustomLayoutProps>,
): JSX.Element {
  const attribute = useCsAttribute();
  return (
    <Grid className="header-grid">
      {attribute.values
        ?.filter((value) => value.isSelected)
        .map((value) => (
          <CsValueController key={value.id} value={value}>
            {props.children}
          </CsValueController>
        ))}
    </Grid>
  );
}

import Box from "@mui/material/Box";
import { SvgIconProps } from "@mui/material/SvgIcon";

import { RegioluxCheckmarkIcon } from "@/icons/RegioluxIcons";

type SubconfigurationCompleteIndicatorProps = {
  isComplete?: boolean;
  color?: SvgIconProps["color"];
};

export function CustomSubconfigurationCompleteIndicatorIcon(
  props: SubconfigurationCompleteIndicatorProps,
): JSX.Element {
  const { isComplete, color } = props;

  return (
    <Box minWidth={"16.75px"}>
      {isComplete && (
        <RegioluxCheckmarkIcon style={{ fontSize: "0.75rem" }} color={color} />
      )}
    </Box>
  );
}

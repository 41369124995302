import { ReactNode } from "react";

import { InfoValues } from "@plansoft-configuration-renderer/components";

import { CustomInfoValues } from "./CustomInfoValues";

/**
 * Props for {@link CustomInfoValuesMapper}
 */
export interface InfoValuesProps {
  children?: ReactNode;
}

interface CustomLayoutProps {
  custom: {
    style?: "headline";
  };
}

export function CustomInfoValuesMapper(
  props: InfoValuesProps & Partial<CustomLayoutProps>,
): JSX.Element {
  const variant = props.custom?.style;
  switch (variant) {
    case "headline":
      return <CustomInfoValues {...props} />;
    default:
      return <InfoValues {...props} />;
  }
}

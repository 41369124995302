import { ReactNode } from "react";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { BasicTable } from "./types";

export function BasicTableBody<
  Row extends { id: number } & { [K in keyof Row]: ReactNode },
>({ headers, rows }: BasicTable<Row>): JSX.Element {
  return (
    <TableBody>
      {rows?.map((row) => (
        <TableRow key={row.id}>
          {headers?.map(({ accessor }) => (
            <TableCell
              key={accessor}
              sx={{
                fontWeight: accessor === "id" ? "700" : null,
              }}
            >
              {row[accessor]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

import Box from "@mui/material/Box";
import { SubconfigurationNavigationVerticalProps } from "@plansoft-configuration-renderer/components";

//was overrided to add scroll to the content
export function CustomSubconfigurationNavigationVertical(
  props: SubconfigurationNavigationVerticalProps,
): JSX.Element {
  return (
    <Box display="flex" gap={2}>
      <Box>{props.navigation}</Box>
      <Box flex={1} overflow="auto">
        {props.content}
      </Box>
    </Box>
  );
}

import {
  AttributeBaseType,
  AttributeGroupProps,
  ConfigurationComponentType,
  SubconfigurationNavigationItemType,
  isSubconfigurationRenderedAsInstances,
  useAttributeGroupHelpers,
  useAttributeOrGroupLayoutCells,
} from "@plansoft-configuration-renderer/components";
import {
  useCsAttributes,
  useCsConfigurationApi,
} from "@plansoft-configuration-renderer/framework";
import {
  PluginHelper,
  ReactPageLayoutCell,
} from "@plansoft-configuration-renderer/layout";
import { useConfigurationAttributeGroupTranslators } from "@plansoft-configuration-renderer/localization";

import { CustomSubconfigurationNavigationItem } from "./CustomSubconfigurationVariantNavigationProps";

type UseCustomSubconfigurationNavigationItemsOptions = Record<string, never>;

export function useCustomSubconfigurationNavigationItems(
  _options: UseCustomSubconfigurationNavigationItemsOptions,
): CustomSubconfigurationNavigationItem[] {
  const cells = useAttributeOrGroupLayoutCells();

  const attributes = useCsAttributes();

  const { resetPath, resetAttributes } = useCsConfigurationApi();

  const translateGroup = useConfigurationAttributeGroupTranslators();
  const { getIsGroupComplete, resetGroup } = useAttributeGroupHelpers();

  function makeAttributeGroupItem(
    childCell: ReactPageLayoutCell,
  ): CustomSubconfigurationNavigationItem[] {
    const { title: groupId, groupBehavior } =
      PluginHelper.getPluginProps<AttributeGroupProps>(childCell);

    const groupOptions = {
      groupId,
      groupBehavior,
      groupLayoutCellId: childCell.id,
    };

    return [
      {
        type: SubconfigurationNavigationItemType.Group,
        layoutCellId: childCell.id,
        path: `group://${groupId}`,
        label: translateGroup.name(groupId),
        isComplete: getIsGroupComplete(groupOptions),
        reset: () => {
          resetGroup(groupOptions);
        },
      },
    ];
  }

  function makeAttributeItems(
    childCell: ReactPageLayoutCell,
  ): CustomSubconfigurationNavigationItem[] {
    const pluginProps = PluginHelper.getPluginProps<{ identifier: string }>(
      childCell,
    );
    const attribute = attributes.find(
      (attribute) => attribute.id === pluginProps.identifier,
    );

    if (!attribute) {
      return [];
    }

    const selectedValue = attribute.values?.find((a) => a.isSelected);

    return [
      {
        type: SubconfigurationNavigationItemType.Attribute,
        layoutCellId: childCell.id,
        path: attribute.path,
        label: attribute.name ?? attribute.id,
        isComplete: attribute.isComplete,
        reset: () => {
          resetAttributes([
            {
              attributePath: attribute.path,
              attributeId: attribute.id,
            },
          ]);
        },
        selectedValue: selectedValue,
      },
    ];
  }

  function makeSubstructureInstanceItems(
    cell: ReactPageLayoutCell,
  ): CustomSubconfigurationNavigationItem[] {
    const pluginProps = PluginHelper.getPluginProps<{ identifier: string }>(
      cell,
    );
    const attribute = attributes.find(
      (attribute) => attribute.id === pluginProps.identifier,
    );

    if (!attribute?.configurations) {
      return [];
    }

    // Add index to the label if there can be multiple instances
    const showIndexInLabel =
      (attribute.cardinalityMax ?? Number.MAX_SAFE_INTEGER) > 1;

    const name = attribute.name ?? attribute.id;

    return attribute.configurations.map((configuration) => {
      return {
        type: SubconfigurationNavigationItemType.Instance,
        layoutCellId: cell.id,
        path: configuration.path,
        label: showIndexInLabel ? `${name} ${configuration.index + 1}` : name,
        isComplete: configuration.isComplete,
        reset: () => {
          resetPath(configuration.path);
        },
      };
    });
  }

  function makeSubstructureItems(
    cell: ReactPageLayoutCell,
  ): CustomSubconfigurationNavigationItem[] {
    if (isSubconfigurationRenderedAsInstances(cell)) {
      return makeSubstructureInstanceItems(cell);
    }

    return makeAttributeItems(cell);
  }

  // extract the plugin data from the layout
  return cells.flatMap((cell) => {
    const pluginId = PluginHelper.getPluginId(cell);

    if (pluginId === AttributeBaseType.Substructure) {
      return makeSubstructureItems(cell);
    }

    if (Object.values<string>(AttributeBaseType).includes(pluginId)) {
      return makeAttributeItems(cell);
    }

    if (pluginId === ConfigurationComponentType.AttributeGroup) {
      return makeAttributeGroupItem(cell);
    }
    // fallback: no items
    return [];
  });
}

import {
  SubstructureDefault,
  SubstructureEmbeddedContent,
  SubstructureLayoutProps,
  SubstructureStub,
  SubstructureVariant,
  useSubstructureLayout,
} from "@plansoft-configuration-renderer/components";

import { CustomSubstructureEmbedded } from "@/renderer/controls/CustomSubstructureEmbeded/CustomSubstructureEmbedded";

const substructureVariants: Record<SubstructureVariant, () => JSX.Element> = {
  [SubstructureVariant.Embedded]: CustomSubstructureEmbedded,
  [SubstructureVariant.Default]: CustomSubstructureEmbedded,
  [SubstructureVariant.Stub]: SubstructureStub,
  [SubstructureVariant.Content]: SubstructureEmbeddedContent,
};

export function CustomSubstructure(
  _props: Partial<SubstructureLayoutProps>,
): JSX.Element {
  const { variant } = useSubstructureLayout();

  const VariantComponent = substructureVariants[variant] ?? SubstructureDefault;
  return <VariantComponent />;
}

CustomSubstructure.displayName = "Substructure";

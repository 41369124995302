import { CustomSubstructureEmbeddedInstance } from "../CustomSubstructureEmbededInstance/CustomSubstructureEmbeddedInstance";

import Box from "@mui/material/Box";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";
import { useCsAttribute } from "@plansoft-configuration-renderer/framework";

/**
 * Embedded sub configuration
 * @returns
 */
export function CustomSubstructureEmbedded(): JSX.Element {
  const attribute = useCsAttribute();

  const substructures: ConfigurationObjectResult[] =
    attribute.configurations ?? [];

  return (
    <Box>
      {substructures.map((substructure, idx) => (
        <CustomSubstructureEmbeddedInstance
          key={idx}
          substructure={substructure}
        />
      ))}
    </Box>
  );
}

CustomSubstructureEmbedded.displayName = "SubstructureEmbedded";

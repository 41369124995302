import { createContext, useContext } from "react";

interface BomContextType {
  isBomOpen: boolean;
  setIsBomOpen: (BOMOpen: boolean) => void;
}

export const BomOpenContext = createContext<BomContextType>({
  isBomOpen: false,
  setIsBomOpen: () => {},
});

export function useBomContext(): BomContextType {
  return useContext(BomOpenContext);
}

import { CustomHelpPopover } from "../CustomHelpPopover/CustomHelpPopover";

import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { BasicHeader } from "./types";

export function BasicTableHeader<Row>({
  headers,
}: {
  headers: BasicHeader<Row>[] | undefined;
}): JSX.Element {
  return (
    <TableHead>
      <TableRow>
        {headers?.map(({ accessor, label, tooltip }) => (
          <TableCell key={accessor}>
            <Box display={"flex"} flexDirection={"row"}>
              <Typography>{label}</Typography>
              {tooltip ? (
                <CustomHelpPopover>
                  <Typography>{tooltip}</Typography>
                </CustomHelpPopover>
              ) : null}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

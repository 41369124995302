import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useCsConfigurationApi } from "@plansoft-configuration-renderer/framework";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";
import { customPalette } from "@/theme/theme";

import { CustomSubconfigurationResetButton } from "./CustomSubconfigurationButton";
import { CustomSubconfigurationCompleteIndicatorIcon } from "./CustomSubconfigurationCompleteIndicatorIcon";
import { CustomSubconfigurationVariantNavigationProps } from "./CustomSubconfigurationVariantNavigationProps";

const Tabs = lazy(() => import("@mui/material/Tabs"));
const Tab = lazy(() => import("@mui/material/Tab"));

/**
 * Tab control on configuration level
 * @category UI
 * @param props
 * @returns
 */

export function CustomSubconfigurationTabsNavigation(
  props: CustomSubconfigurationVariantNavigationProps,
): JSX.Element {
  const {
    items,
    activePath,
    setActivePath: setActiveItem,
    navigationStyle,
  } = props;

  const { selectedSubstructure } = useSubstructureStandaloneContentCtx();

  const { resetCurrentCfgObject } = useCsConfigurationApi();

  const { t } = useTranslation();

  return (
    <Suspense>
      <Box
        bgcolor={customPalette.primary.light}
        display={"flex"}
        flexDirection={"column"}
        height="100%"
      >
        {selectedSubstructure && (
          <Tab
            key={selectedSubstructure.id}
            className="tab-header"
            label={
              <Typography fontWeight={500}>
                {selectedSubstructure.name}
              </Typography>
            }
          />
        )}
        <Tabs
          variant="scrollable"
          orientation={props.orientation}
          onChange={(_, newValue) => setActiveItem(newValue)}
          value={activePath}
          sx={{
            height: "100%",
            ...navigationStyle,
            position: "inherit",
          }}
        >
          {items.map((item) => (
            <Tab
              key={item.path}
              value={item.path}
              className={
                item.type === "ConfigurationAttribute"
                  ? item.selectedValue
                    ? "complete"
                    : ""
                  : item.isComplete
                    ? "complete"
                    : ""
              }
              label={
                item.selectedValue?.name || item.selectedValue?.id ? (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"left"}
                  >
                    <Typography variant="body2">{item.label}</Typography>
                    <Typography variant="h6" textTransform={"uppercase"}>
                      {item.selectedValue?.name ?? item.selectedValue?.id}
                    </Typography>
                  </Box>
                ) : (
                  <>{item.label}</>
                )
              }
              icon={
                <CustomSubconfigurationCompleteIndicatorIcon
                  isComplete={
                    item.type === "ConfigurationAttribute"
                      ? item.selectedValue
                        ? true
                        : false
                      : item.isComplete
                  }
                />
              }
            />
          ))}
          {/* Empty tab to create a small gap to ResetButton */}
          <Tab
            label=" " // Empty label to have same styling as other tabs
            icon={
              <CustomSubconfigurationCompleteIndicatorIcon isComplete={false} /> // inComplete icon to have same styling as other tabs
            }
          />
        </Tabs>
        <CustomSubconfigurationResetButton
          onResetClicked={resetCurrentCfgObject}
          label={t("reset_Configuration")}
        />
      </Box>
    </Suspense>
  );
}

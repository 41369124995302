import {
  Substructure,
  SubstructureLayoutProps,
} from "@plansoft-configuration-renderer/components";

import { CustomSubstructure } from "@/renderer/controls/CustomSubstructure/CustomSubstructure";

export function SubstructureMapper(
  props: Partial<SubstructureLayoutProps>,
): JSX.Element {
  //overrides only the substructure with renderContentAsStandalone and keeps the default behaviour for the rest
  if (props.custom?.renderContentAsStandalone) {
    return <CustomSubstructure {...props} />;
  }
  return <Substructure {...props} />;
}

SubstructureMapper.displayName = "SubstructureMapper";

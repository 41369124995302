import { createRoot } from "react-dom/client";

import App from "./App";
import "./index.css";
import { logVersion } from "./logVersion";
import "./renderer/translations/i18n";
import reportWebVitals from "./reportWebVitals";
import { contentHeightResizeObserver } from "./tools/helper";

const rootElement = document.getElementById("root");
logVersion();

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
  contentHeightResizeObserver.observe(rootElement);
} else {
  console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

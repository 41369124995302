import { createContext, useContext, useState } from "react";

import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";

type SubstructureCtx = {
  selectedSubstructure: ConfigurationObjectResult | null;
  setSelectedSubstructure: (
    substructure: ConfigurationObjectResult | null,
  ) => void;
};

export const SubstructureCtx = createContext<SubstructureCtx>({
  selectedSubstructure: null,
  setSelectedSubstructure: () => {},
});

/**
 * Context to track the selected substructure, to render it's content in the StandaloneSubstructureContent
 * */
export function SubstructureStandaloneContentCtxProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [selectedSubstructure, setSelectedSubstructure] =
    useState<null | ConfigurationObjectResult>(null);

  return (
    <SubstructureCtx.Provider
      value={{ selectedSubstructure, setSelectedSubstructure }}
    >
      {children}
    </SubstructureCtx.Provider>
  );
}

export function useSubstructureStandaloneContentCtx(): SubstructureCtx {
  return useContext(SubstructureCtx);
}

import { CustomSubstructureInstanceButtons } from "../CustomSubstructureInstanceButtons/CustomSubstructureInstanceButtons";

import { Fragment } from "react";

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";

import { CustomProductCard } from "@/components/CustomProductCard/CustomProductCard";

/**
 * Props for {@linkcode CustomSubstructureEmbeddedInstance}
 */
export interface SubstructureEmbeddedInstanceProps {
  substructure: ConfigurationObjectResult;
}

/**
 * Single instance of a sub configuration
 * @param props
 * @returns
 */
export function CustomSubstructureEmbeddedInstance(
  props: SubstructureEmbeddedInstanceProps,
): JSX.Element {
  const { substructure } = props;

  const summary = (
    <Grid container>
      <Box display={"flex"} flexDirection={"column"} gap={"0.25rem"}>
        <CustomProductCard substructure={substructure} />
        <Box display="flex" marginBottom={"1rem"}>
          <CustomSubstructureInstanceButtons substructure={substructure} />
        </Box>
      </Box>
    </Grid>
  );

  return <Fragment>{summary}</Fragment>;
}

CustomSubstructureEmbeddedInstance.displayName =
  "CustomSubstructureEmbeddedInstance";

import { queryClient } from "../App";

import { Fragment, useCallback } from "react";

import { ConfigurationChildEventUnion } from "@plansoft-configuration-renderer/events";
import { useConfigurationEvent } from "@plansoft-configuration-renderer/framework";

/**
 * Demo on how to access internal state
 * @returns
 */
export function ConfigurationStateSpy(): JSX.Element {
  const onConfigurationEvent = useCallback(
    async (payload: ConfigurationChildEventUnion) => {
      if (payload.cfgEvent === "configuration-changed") {
        //invalidate Query after each change
        queryClient.invalidateQueries({ queryKey: ["productInfo"] });
      }
    },
    [],
  );

  useConfigurationEvent({
    // onConfigurationStarted: onConfigurationEvent,
    // onConfigurationLoaded: () => console.log("**CFG** load"),
    // onConfigurationResumed: () => console.log("**CFG** resume"),
    onConfigurationEvent: onConfigurationEvent,
    // onConfigurationNavigation: () => console.log("**CFG** navigation"),
  });

  return <Fragment />;
}

export const de_DE = {
  translation: {
    show_debug_drawer: "Show Debug Drawer",
    // previous_Configuration: "Zurück",
    next_Configuration: "Weiter",
    reset_Configuration: "Auswahl zurücksetzen",
    reset_SubConfiguration: "Gruppe zurücksetzen",
    header_BOM: "Stückliste",
    sum_BOM: "Summe Positionen",
    add_XAS_GERAETETRAEGER: "weitere Geräteträger hinzufügen",
    add_XAS_FUNKTIONSMODUL: "Funktionsmodul hinzufügen (Optional)",
    add_XAS_TRAGSCHIENE: "Tragschiene hinzufügen",
    add_BOM: "Konfigurationsergebnis anzeigen",
    sidebar_Header: "SRT KONFIGURATOR",
    add_to_shopping_cart: "Zum Warenkorb hinzufügen",
  },
};

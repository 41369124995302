export function tryParseOutputData<T>(data: Partial<T>): T {
  if ("output" in data && typeof data["output"] === "string") {
    try {
      const result = JSON.parse(data["output"]);
      if (result && typeof result === "object") {
        data["output"] = result;
      }
    } catch (error) {
      console.error("Error parsing output data:", error);
    }
  }
  return data as T;
}

export const contentHeightResizeObserver = new ResizeObserver(() => {
  const height = document.documentElement.getBoundingClientRect().height;
  window.parent.postMessage(height + 10, "*"); // +10px to avoid scrollbars
});

import {
  ConfigurationNavigation,
  ConfigurationNavigationProps,
} from "@plansoft-configuration-renderer/components";
// needs GatewayProvider and Gateway to work
import { Gateway } from "@plansoft-configuration-renderer/gateway";

export function NavigationOutsideLayout(
  props: ConfigurationNavigationProps,
): JSX.Element {
  console.log(props);
  return (
    <Gateway into="navigation">
      <ConfigurationNavigation {...props} />
    </Gateway>
  );
}

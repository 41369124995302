import { BillOfMaterialsData } from "../BillOfMaterials/BillOfMaterialsData";
import { BomButton } from "../BillOfMaterials/BomButton";
import { StandaloneSubstructureContent } from "../StandaloneSubstructureContent/StandaloneSubstructureContent";

import Alert from "@mui/material/Alert";
import { CustomLayoutProps } from "@plansoft-configuration-renderer/layout";
import { useUiTranslation } from "@plansoft-configuration-renderer/localization";

import { ProductInfoData } from "@/components/ProductInfo/ProductInfoData";
import { CustomSubstructureAddButton } from "@/renderer/controls/CustomSubstructureAddButton/CustomSubstructureAddButton";
import { ProvideAttributeContextByPath } from "@/renderer/controls/ProvideAttributeContextByPath/ProvideAttributeContextByPath";

/**
 * Props for {@link IndividualComponentMapper}
 */
export type IndividualComponentMapperProps = {
  variant:
    | "product_info"
    | "bill_of_materials"
    | "substructure_standalone_content"
    | "add_substructure_button"
    | "show_BOM"
    | string
    | null; //extend this when new variants are added
} & Required<CustomLayoutProps>;

export interface CustomIndividualComponentMapperProps {
  custom: {
    icon?: "add" | "lightBulb" | "supportRail" | "bom" | string;
  };
}

export function IndividualComponentMapper({
  variant,
  custom,
}: IndividualComponentMapperProps &
  Partial<CustomIndividualComponentMapperProps>): JSX.Element {
  const { t } = useUiTranslation();

  if (variant === "product_info") {
    return <ProductInfoData />;
  } else if (variant === "bill_of_materials") {
    return <BillOfMaterialsData />;
  } else if (variant === "substructure_standalone_content") {
    return <StandaloneSubstructureContent />;
  } else if (variant === "show_BOM") {
    return <BomButton custom={custom} />;
  } else if (variant?.includes("add_substructure_button")) {
    //expects the variant in the form of add_substructure_button:{path}
    const path = variant.split(":")[1];
    if (path) {
      return (
        <ProvideAttributeContextByPath path={path}>
          <CustomSubstructureAddButton custom={custom} />
        </ProvideAttributeContextByPath>
      );
    }

    console.error(
      "Path not provided for add_substructure_button variant, check layout editor specification",
    );
  }

  return (
    <Alert severity="warning">
      {t(
        `Individual component ${variant} not supported`,
        `Individual component ${variant} is not supported by the default renderer`,
      )}
    </Alert>
  );
}
